<template>
  <div class="custom-dropdown" @mouseleave="hideDropdown">
    <div
        @click="toggleDropdown"
        class="dropdown-header"
    >
      <span class="dropdown-text">{{ selectedOption || placeholder }}</span>
      <span class="arrow" :class="{ 'arrow-up': isOpen }">▼</span>
    </div>
    <ul v-if="isOpen" class="dropdown-list" >
      <li
          v-for="option in options"
          :key="option.id"
          :class="{'active': currentId === option.id}"
          @click="selectOption(option)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
  },
  data() {
    return {
      isOpen: false,
      currentId: -1,
      selectedOption: null
    }
  },
  methods: {
    hideDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option.name;
      this.currentId = option.id;
      this.isOpen = false;
      this.$emit('selected', option.id, option.name);
    },
    reset() {
      this.selectedOption = null;
      this.currentId = -1;
      this.isOpen = false;
      this.$emit('selected', this.currentId, this.selectedOption);
    }
  }
}
</script>

<style scoped>
.custom-dropdown {
  width: 184px;
  height: 32px;
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--component-background);
  border: var(--buttons-border-width) solid var(--component-border-color);
  border-radius: var(--border-radius);
}

.dropdown-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  font-size: 14px;
  height: 32px;
  max-height: 32px;
}

.dropdown-text {
  max-height: 32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  font-size: 14px;
  background: var(--component-background);
  border-radius: var(--border-radius);
  border: var(--buttons-border-width) solid var(--component-border-color);
}

.dropdown-list li {
  height: auto;
  width: 90%;
  margin: 0 5%;
  padding: 5px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dotted var(--component-border-color);
}

.dropdown-list li:hover {
  opacity: 0.8;
}

.dropdown-list li.active {
  color: var(--links-color);
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(180deg);
}
</style>